$(document).ready(function() {

    $playerElement = $('#video-player');

    // inBody ernährungskurs braucht zeitgestauerte modals
    const additionalAttribute = $playerElement.attr('data-media-item-show-modals');
    modalTimes = [];

    if (typeof additionalAttribute !== 'undefined' && additionalAttribute !== false) {

        const additionalValue = $.parseJSON(additionalAttribute);

        if (additionalValue.hasOwnProperty('modal_times')) {
            // array bauen um später die markierungen leicht entfernen zu können
            modalTimes = Object.entries(additionalValue['modal_times']);
            //console.log(modalTimes);
        }
    }


    //init only on mediaItem page
    if ($('#video-player').length == 1) {

        //init video player
        mediaItemUrl = $playerElement.attr('data-media-item-url');
        mediaItemType = $playerElement.attr('data-media-item-type');

        mediaItemSecondaryUrl = $playerElement.attr('data-media-item-secondary-url');
        mediaItemSecondaryType = $playerElement.attr('data-media-item-secondary-type');

        player = videojs('video-player',
            {},
            function callback() {
                player.src([
                    {src: mediaItemUrl, type: mediaItemType},
                    {src: mediaItemSecondaryUrl, type: mediaItemSecondaryType}
                ]);
            }
        );

    }
    else if($('#audio-player').length == 1) {

        $playerElement = $('#audio-player');

        //init audio player
        mediaItemUrl = $playerElement.attr('data-media-item-url');
        mediaItemType = $playerElement.attr('data-media-item-type');

        player = videojs('audio-player',
            {},
            function callback() {
                player.src([
                    {src: mediaItemUrl, type: mediaItemType},
                ]);
            }
        );

    }
    else {

        //no valid players found stop script
        return;
    }


    const markerLabel = "Ihr Fortschritt";


    //generic delay function. Use for keyup event throttling
    delay = (function() {
        var timer = 0;
        return function(callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();


    player.ready(function() {

        lastProgress = player.currentTime();
        lastStoredProgress = player.currentTime();

        mediaItemId = player.getAttribute('data-media-item-id');

        //allow only jumping to left hand side
        player.on("seeking", function(event) {
            if (lastStoredProgress < player.currentTime()) {
                player.currentTime(lastStoredProgress);
            }
        });

        //allow only jumping to left hand side
        player.on("seeked", function(event) {
            if (lastStoredProgress < player.currentTime()) {
                player.currentTime(lastStoredProgress);
            }
        });

        //prevent user from jumping over the current progress marker !
        player.on('timeupdate', function(event) {

            if (event.manuallyTriggered == true) {
                return;
            }

        });

        //handle ending of mediaItem
        player.on('ended', function(event) {

            storeProgressOnServer(mediaItemId, player.duration());

            //close fullscreen
            if(player.isFullscreen()) {
                player.exitFullscreen();
            }

            //display questions modal
            if(player.getAttribute('data-media-item-show-questions') == 'true') {
                $('#questions-modal').modal({
                    show: true,
                    backdrop: 'static'
                });
            }

        });

        //fired after .mpd was read
        player.on('loadedmetadata', function() {

            //init current progress state via saved state on server
            $.ajax({
                url: APP_URL + '/media-item/progress/' + mediaItemId,
                method: 'GET',
                dataType: 'json'
            }).done(function(data, textStatus, jqXHR) {

                lastStoredProgress = data;

                //mark current stored progress in progressbar
                player.markers.removeAll();
                player.markers.add([
                    {
                        time: lastStoredProgress,
                        put: markerLabel,
                    },
                ]);
            });
        });

        // update media progress every x seconds
        setInterval(function() {
            if (!player.paused()) {

                var currentProgress = player.currentTime();

                var timeSinceLastProgress = currentProgress - lastStoredProgress;

                if (timeSinceLastProgress >= 20.0) {
                    storeProgressOnServer(mediaItemId, currentProgress);
                }

                // modals für inbody einblenden?
                if(modalTimes.length > 0) {

                    // letztes modal finden, dass vor der aktuellen zeit kommt
                    let nextModal = null;
                    $.each(modalTimes, function(index, modalData) {

                        let nextModalTimeSplitted = modalData[0].split(':');
                        let nextModalTimeSeconds = parseInt(nextModalTimeSplitted[0]) * 60 + parseInt(nextModalTimeSplitted[1]);

                        // modal zeigen
                        if(nextModalTimeSeconds < parseInt(currentProgress)) {
                            nextModal = modalData[1];
                        }

                    });

                    if(nextModal !== null) {

                        let sectionName = 'section-' + nextModal;

                        $('#modalForm section').addClass('d-none');
                        $('#modalForm').find('.' + sectionName).removeClass('d-none');

                        $('#modalForm .modal-title').text($('#modalForm').find('.' + sectionName).attr('data-title'));

                        $('#modalForm').modal({
                            show: true,
                            backdrop: 'static'
                        });

                        player.pause();

                        // entscheide welche felder an welche URL gepostet werden
                        switch(nextModal) {
                            case 'gewicht':
                                inputFieldSelector = '#modalForm #weight, #modalForm [name="_token"]';
                                postUrl = INBODY_USER_DATA_POST_WEIGHT_URL;
                                break;
                            case 'whr':
                                inputFieldSelector = '#modalForm #waist, #modalForm #hip, #modalForm #whr, #modalForm [name="_token"]';
                                postUrl = INBODY_USER_DATA_POST_WHR_URL;
                                break;
                            case 'bauchumfang':
                                inputFieldSelector = '#modalForm #abdominal, #modalForm [name="_token"]';
                                postUrl = INBODY_USER_DATA_POST_BAUCHUMFANG_URL;
                                break;
                            case 'bmi':
                                inputFieldSelector = '#modalForm #height, #modalForm #bmi, #modalForm [name="_token"]';
                                postUrl = INBODY_USER_DATA_POST_BMI_URL;
                                break;
                            case 'pal':
                                inputFieldSelector = '#modalForm #birthday, #modalForm [name="sex"], #modalForm #metabolic_rate, #modalForm [name="_token"]';
                                postUrl = INBODY_USER_DATA_POST_PAL_URL;
                                break;
                        }

                        // handler für absenden der eingaben
                        $('#modalForm .modal-footer .btn-primary').off().on('click',
                            {
                                postUrl: postUrl,
                                modal: $('#modalForm'),
                                selector: inputFieldSelector,
                                player: player
                            }, storeUserWeight);

                        modalTimes.shift();
                    }

                }

                lastProgress = player.currentTime();
            }
        }, 1000);

    });

    //init video.js makers plugin
    player.markers([]);

    //persist current progress state on server
    storeProgressOnServer = function(mediaItemId, currentProgress) {

        var data = {
            mediaItemId: mediaItemId,
            progress: currentProgress,
        };

        //store current mediaItem progress on server
        $.ajax({
            url: APP_URL + '/media-item/progress',
            method: 'POST',
            context: player,
            data: { data: data, '_token': csrf_token },
            dataType: 'json'
        }).done(function(data, textStatus, jqXHR) {

            lastStoredProgress = currentProgress;

            //mark current stored progress in progressbar
            player.markers.removeAll();
            player.markers.add([
                {
                    time: lastStoredProgress,
                    put: markerLabel,
                },
            ]);

        }).always(function(dataOrjqXHR, textStatus, jqXHRorErrorThrown) {

        });
    }

});
