/**
 * Created by nils on 04.03.19.
 */

$(document).ready(function() {

    //on click radio button: validate question & answer
    $('#questions-modal input.answer').on('click', function(e) {

        //reset validation
        $('#questions-modal .question label').removeClass('bg-warning').removeClass('bg-success');

        //validate answers
        var isCorrect = true;
        $('#questions-modal .answer').each(function() {

            if ($(this).attr('data-is-correct') == '0') {
                //mark false positive answer
                $(this).closest('div').find('label').addClass('');
            }

            if ($(this).attr('data-is-correct') == '1') {
                //mark correct answer
                $(this).closest('div').find('label').addClass('bg-success');

                if ($(this).is(':checked')) {
                    isCorrect = true;
                }
                else {
                    isCorrect = false;
                }
            }
        });

        //change text of submit form button
        if (isCorrect) {
            $('#questions-modal .feedback-box').text('Die Antwort ist korrekt!');
        }
        else {
            $('#questions-modal .feedback-box').text('Die Antwort ist falsch!');
        }

        $('#questions-modal .modal-footer button').removeClass('disabled');
    });


    //on click footer button: save result on server
    $('#questions-modal .modal-footer button').on('click', function(e) {

        e.preventDefault();

        if ($(this).hasClass('disabled')) {
            return true;
        }

        $('#questions-modal .modal-footer button').addClass('disabled');

        var data = {

            mediaItemId: $('.media-item-player').attr('data-media-item-id'),
        };

        //tell server
        $.ajax({
            url: APP_URL + '/media-item/answers',
            method: 'POST',
            dataType: 'json',
            data: { data: data, '_token': csrf_token },
        }).done(function(result, textStatus, jqXHR) {

            if (result == true || result == false) {
                //result wayne
                window.location.href = DASHBOARD_URL;
            }
            else {
                alert("Fehler bei der Verarbeitung. Bitte versuche es erneut.");
            }

        }).fail(function(jqXHR, textStatus, errorThrown) {
            alert("Fehler bei der Verarbeitung. Bitte versuche es erneut.");
        }).always(function() {
            $('#questions-modal .modal-footer button').removeClass('disabled');
        });

    });


    $('#questions-modal').on('shown.bs.modal', function(e) {
        resetQuiz();
    });

    resetQuiz = function() {
        $('#questions-modal .answer').prop('checked', false);
        $('#questions-modal .modal-footer button').addClass('disabled');
        $('#questions-modal .modal-footer .feedback-box').empty();
        $('#questions-modal .question label').removeClass('bg-warning').removeClass('bg-success');
    };

    //show additional "questions" buttons for mediaItems in "completed" state
    $('.player #show-questions').on('click', function(e) {
        e.preventDefault();
        $('#questions-modal').modal({
            show: true,
            backdrop: 'static'
        });
    });


});
