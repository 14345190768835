/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/**
 * vendor stuff
 */

// bootstrap select
require('bootstrap-select');

// bootstrap datepicker
require('bootstrap-datepicker');

//video.js player
window.videojs = require('video.js').default;

//video.js player plugins
require('videojs-markers');
require('videojs-contrib-dash');

//general dash support for browsers
require('dashjs');

// slick carousel
require('../../node_modules/slick-carousel/slick/slick.js');


/**
 * custom stuff
 */

//mediaItem player
require('./media-item-player.js');

//questions modal
require('./question-modal.js');

// shopify buy button config
require('./shopify-buy-btn');

/**
 * general frontend stuff
 */

//sticky navbar
$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};
$(window).on('load resize scroll', function() {

    if ($('.stick').length > 0 && $('.stick').isInViewport()) {
        $('.btn-media-item').removeClass('stuck');
    } else {
        $('.btn-media-item').addClass('stuck');
    }
});
$(document).scroll(function() {
    var $nav = $(".navbar.fixed-top");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
});
$('.hamburger').click(function() {
    $(this).toggleClass('is-active');
});

$(function() {
    var duration = 4000; // 4 seconds
    setTimeout(function() {
        $('.alert-timeout').fadeOut();
    }, duration);
});


// client side form validations
(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            var password = document.getElementById('password');
            form.addEventListener('submit', function(event) {

                // compare passwords ?
                var pw1 = document.getElementById('password');
                var pw2 = document.getElementById('password_confirmation');

                if (pw1 != null && pw2 != null) {

                    if (pw1.value !== pw2.value) {
                        pw2.setCustomValidity("Missmatch");
                    } else {
                        pw2.setCustomValidity('');
                    }
                }

                // compare emails ?
                var email1 = document.getElementById('email');
                var email2 = document.getElementById('email_confirmation');

                if (email1 != null && email2 != null) {
                    if (email1.value !== email2.value) {
                        email2.setCustomValidity("Missmatch");
                    } else {
                        email2.setCustomValidity('');
                    }
                }

                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();






// krankeversicherungs rechner
$(function() {
    $('.selectpicker').selectpicker();

    // kv rechner
    $('#kv-calculator').on('change', function(e) {

        $('.spinner').toggleClass('d-none');
        $('.kv-calculator-text').addClass('d-none');
        $('.kv-calculator-price').addClass('d-none');
        $('.kv-calculator-hint').addClass('d-none');

        $.get("/ajax/calc-kv-refund/" + $(this).val())
            .done(function(data) {
                $('.kv-calculator-price').html(data.refund + " &euro;").removeClass('d-none');
                $('.kv-calculator-text').removeClass('d-none');
                // $('.refund').html(data.refund + " &euro;").removeClass('d-none');
                // $('.resultPrice').html(data.resultPrice + " &euro;").removeClass('d-none');
                // console.log(data);
                // diverse hinweise verkettten
                var hintArray = [];

                // hinweistext ausgeben
                if (typeof data.additionalHint !== "undefined" && data.additionalHint != "") {
                    hintArray.push(data.additionalHint);
                }

                var hintString = hintArray.join(', ');

                $('.kv-calculator-hint').html(hintString).removeClass('d-none');
            })
            .fail(function() {
            })
            .always(function() {
                $('.kv-calculator-results .fa-spinner').toggleClass('d-none');
            });

    });
});
