/**
 *  ShopifyBuy Button im Checkout
 *
 *  Konfiguration siehe: http://shopify.github.io/buy-button-js/
 *
 */

ShopifyBuyInit = function() {

    var client = ShopifyBuy.buildClient({
        domain: 'mobilo-praventionskurse.myshopify.com',
        storefrontAccessToken: 'ff8a15b5d04d5b12967fc1db5c7b9496',
    });

    var ui = ShopifyBuy.UI.init(client);

    ui.createComponent('product', {
        id: '6236142076094',
        node: document.getElementById('product-component-1613996846593'),
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
        options: {
            "product": {
                "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                            "max-width": "calc(25% - 20px)",
                            "margin-left": "20px",
                            "margin-bottom": "50px"
                        }
                    }
                },
                "buttonDestination": "checkout",
                "text": {
                    "button": "Buy now"
                }
            },
            "productSet": {
                "styles": {
                    "products": {
                        "@media (min-width: 601px)": {
                            "margin-left": "-20px"
                        }
                    }
                }
            },
            "modalProduct": {
                "contents": {
                    "img": false,
                    "imgWithCarousel": true,
                    "button": false,
                    "buttonWithQuantity": true
                },
                "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0px",
                            "margin-bottom": "0px"
                        }
                    }
                },
                "text": {
                    "button": "Add to cart"
                }
            },
            "option": {},
            "cart": {
                "text": {
                    "total": "Subtotal",
                    "button": "Checkout"
                }
            },
            "toggle": {}
        },
    });

}

